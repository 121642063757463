<template>
  <el-container style="height: 100vh">
    <el-aside width="200px" class="left">
      <div class="user">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          ref="upload"
          accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
          :headers="token"
          :on-preview="handlePictureCardPreview"
          :http-request="uploadFile"
        >
          <img :src="userPic.imagesAddr" />
        </el-upload>
        <div>
          <p>{{ User.userName }}</p>
          <p>{{ User.post }}</p>
        </div>
      </div>
      <div class="other">
        <div v-for="(item, index) in leftList" :key="index">
          <div
            class="item"
            @click="clickLeftList(index, item)"
            :class="{
              leftActive: index == leftListIndex,
              disableClass: item.text == '审核中心' && isDisabled,
            }"
            v-if="
              (controlPageIndex != 0 && item.text == '控制中心') ||
              item.text != '控制中心'
            "
          >
            <img
              :src="index == leftListIndex ? item.img2 : item.img1"
            /><span>{{ item.text }}</span>
            <i
              v-if="
                item.text == '文件落地检查' ||
                item.text == '学习中心' ||
                item.text == '统计分析'
              "
              :class="
                showI && index == leftListIndex
                  ? 'el-icon-arrow-down'
                  : 'el-icon-arrow-up'
              "
            ></i>
          </div>
          <template
            v-if="
              pageShowTitle == '文件落地检查' && showI && leftListIndex == index
            "
          >
            <div
              v-for="(item, index) in chindleList1"
              :key="index"
              class="chind"
              @click="clickChind(index, item)"
            >
              <span class="poin" v-if="chindIndex == index">●</span>
              <span :class="{ chindActive: chindIndex == index }">
                {{ item.text }}</span
              >
            </div>
          </template>
          <template
            v-if="
              pageShowTitle == '学习中心' && showI && leftListIndex == index
            "
          >
            <div
              v-for="(item, index) in chindleList2"
              :key="index + 2"
              class="chind"
              @click="clickChind(index + 2, item)"
            >
              <span class="poin" v-if="chindIndex == index + 2">●</span>
              <span :class="{ chindActive: chindIndex == index + 2 }">
                {{ item.text }}</span
              >
            </div>
          </template>
          <template
            v-if="
              pageShowTitle == '统计分析' && showI && leftListIndex == index
            "
          >
            <div
              v-for="(item, index) in chindleList3"
              :key="index + 5"
              class="chind"
              @click="clickChind(index + 5, item)"
            >
              <span class="poin" v-if="chindIndex == index + 5">●</span>
              <span :class="{ chindActive: chindIndex == index + 5 }">
                {{ item.text }}</span
              >
            </div>
          </template>
        </div>
      </div>
      <div class="news">
        <div>
          <img src="../../assets/images/img1/home1.png" />
          <span>消息</span>
        </div>
        <div>
          <img src="../../assets/images/img1/home1.png" />
          <span>客服</span>
        </div>
      </div>
    </el-aside>
    <el-container>
      <el-header class="header">
        <div class="title">
          <img
            class="backimg"
            src="../../assets/images/Examine_img/back.png"
            @click="$router.push('./page')"
          />
          <span v-if="newValpath != '/test' && newValpath != '/score'">{{
            pageTitle
          }}</span>
          <span
            v-show="newValpath == '/test' && oldValpath != '/score'"
            @click="$router.go(-1)"
            >🔙考试</span
          >
          <span
            v-show="newValpath == '/score' && oldValpath == '/test'"
            @click="$router.go(-2)"
            >🔙考试</span
          >
          <span
            v-show="newValpath == '/test' && oldValpath == '/score'"
            @click="$router.go(-3)"
            >🔙考试</span
          >
          <span
            v-show="newValpath == '/AssignTest' && oldValpath == '/assignStudy'"
            @click="$router.go(-1)"
            >🔙指派考试</span
          >
          <span
            v-show="newValpath == '/AssignScore' && oldValpath == '/AssignTest'"
            @click="$router.go(-2)"
            >🔙指派考试</span
          >
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="black"
          active-text-color="#5a8bff"
          @select="handleSelect"
        >
          <el-submenu
            :index="index + ''"
            v-for="(item, index) in $store.state.menuList"
            :key="index"
          >
            <template slot="title">{{ item.title }}</template>
            <el-menu-item
              :index="sonItem.path"
              v-for="(sonItem, sonIndex) in item.son"
              :key="sonIndex"
              >{{ sonItem.title }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
        <div class="about">关于</div>
        <div class="pack-up" @click="loginUp">
          <img src="../../assets/images/img1/loginUp.png" alt="" />
          <p>退出登录</p>
        </div>
      </el-header>
      <el-main class="main">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <PageDialog
          :dialogIndex="dialogIndex"
          @handleClose="handleClose"
          :showDialog="showDialog"
        ></PageDialog>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import PageDialog from '../../components/dialog/PageDialog.vue'
import { get, post, baseURL } from '../../api/http'
import { compressImage } from '../../modules/CompressImage'

export default {
  components: {
    PageDialog,
  },
  data() {
    return {
      BUrl: baseURL[process.env.NODE_ENV + ''],
      token: {
        Authorization:
          'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      },
      userPic: {
        imagesAddr:'',
      },
      showDialog: false,
      dialogIndex: 0,
      User: JSON.parse(sessionStorage.getItem('userInfo')),
      isDisabled: false,
      oldValpath: '',
      newValpath: '',
      pageTitle: '',
      activeIndex: null,
      leftListIndex: null,
      controlPageIndex: 0,
      leftList: [
        // {
        //   text: '现场检查',
        //   img1: require('../../assets/images/img1/home1.png'),
        //   img2: require('../../assets/images/img1/home11.png'),
        //   path: '/Examine_work',
        // },
        // {
        //   text: '文件落地检查',
        //   img1: require('../../assets/images/img1/home2.png'),
        //   img2: require('../../assets/images/img1/home22.png'),
        //   path: '/Flies_work',
        // },
        {
          text: '审核中心',
          img1: require('../../assets/images/img1/home7.png'),
          img2: require('../../assets/images/img1/home77.png'),
          path: '/mixing',
        },
        // {
        //   text: '内外审问题点',
        //   img1: require('../../assets/images/img1/home7.png'),
        //   img2: require('../../assets/images/img1/home77.png'),
        //   path: '/QuestionPoint',
        // },
        {
          text: '学习中心',
          img1: require('../../assets/images/img1/home4.png'),
          img2: require('../../assets/images/img1/home44.png'),
          path: '/Lean',
        },
        {
          text: '档案管理',
          img1: require('../../assets/images/img1/home6.png'),
          img2: require('../../assets/images/img1/home66.png'),
          path: '/archives-manage',
        },
        {
          text: '控制中心',
          img1: require('../../assets/images/img1/home5.png'),
          img2: require('../../assets/images/img1/home55.png'),
          path: '/ControlPage',
        },
        {
          text: '统计分析',
          img1: require('../../assets/images/img1/home2.png'),
          img2: require('../../assets/images/img1/home22.png'),
          path: '/quarter',
        },
      ],
      chindIndex: null,
      chindleList1: [
        {
          text: '文件检查',
          path: './Flies_work',
        },
        {
          text: '考核细则',
          path: './ExamineRules',
        },
      ],
      chindleList2: [
        {
          text: '文件学习',
          path: './Lean',
        },
        {
          text: '专题学习',
          path: './special-subject',
        },
        // {
        //   text: '风控学习',
        //   path: './risk-subject',
        // },
      ],
      chindleList3: [
        {
          text: '季度统计',
          path: './quarter',
        },
        {
          text: '近期问题点统计',
          path: './question-points',
        },
        {
          text: '新增问题列表',
          path: './add-question-record',
        },
        {
          text: '监控记录',
          path: './monitor-record',
        },
        {
          text: '审核员统计',
          path: './auditor-statistics',
        },
        {
          text: '模考成绩统计',
          path: './exam-score-statistics',
        },
        {
          text: '公司部门审核统计表',
          path: './company-dept-audit',
        },
        {
          text: '文件检查条款统计表',
          path: './fileExam-Clause-Statis',
        },
        {
          text: '7S审核分数汇总',
          path: '/audit-records',
        },
        {
          text: '年度审核考试分析表',
          path: '/annual-audit-exam',
        },
        {
          text: '安全考核细则分析表',
          path: '/safety-assessment-rules',
        },
      ],
      menuList: [
        {
          title: '系统设置',
          son: [
            {
              path: '同步OA',
              title: '同步OA组织结构',
            },
            {
              path: '/function-manage',
              title: '职能管理',
            },
            {
              path: '/Company_Dept',
              title: '公司与部门设置',
            },
            {
              path: '/Post_setting',
              title: '岗位设置',
            },
            {
              path: '/Person_Setting',
              title: '人员设置',
            },
            {
              path: '/role-permissions',
              title: '角色权限设置',
            },
            {
              path: '/job-fun-maintain',
              title: '岗位与职能维护',
            },
            {
              path: '更改密码',
              title: '更改登录密码',
            },
            {
              path: '/time-monitoring',
              title: '设置时间监控',
            },
            {
              path: '/controlApply',
              title: '控制权限申请',
            },
          ],
        },
        {
          title: '基础设置',
          son: [
            {
              path: '/region-manage',
              title: '区域管理',
            },
            {
              path: '/category',
              title: '涉及范畴',
            },
            {
              path: '/file-class-manage',
              title: '文件实施类别管理',
            },
            // {
            //   path: '/plan',
            //   title: '审核计划',
            // },
            // {
            //   path: '/mixing',
            //   title: '混合审核',
            // },
            // {
            //   path: '/assignStudy',
            //   title: '指派学习',
            // },
            // {
            //   path: '/post-fun-set',     //使用下标切割和存储
            //   title: '岗位职能表权限设置',
            // },
            {
              path:'/plate',
              title:'模块设置',
            },
          ],
        },
        {
          title: '题库管理',
          son: [
            {
              path: '/maintain',
              title: '题库维护',
            },
            // {
            //   path: '/wind-control-maintain',
            //   title: '风控题库维护',
            // },
            // {
            //   path: '/wind-control-import',
            //   title: '风控题库导入',
            // },
            // {
            //   path: '/interim',
            //   title: '临时题库（安全）',
            // },
            {
              path: '/scrutiny',
              title: '内外审问题点题库',
            },
            {
              path: '/import',
              title: '题库导入',
            },

            {
              path: '/7Simport',
              title: '7S题库导入',
            },
            {
              path: '/exam',
              title: '考试题库',
            },
            {
              path: '/theme-import',
              title: '专题题库',
            },
            {
              path: '/general-questions',
              title: '通用题目题库',
            },
            {
              path: '/post-fun-table', //需调接口判断是否显示入口，放最后方便判断
              title: '岗位职能表',
            },
          ],
        },
      ],
      showI: false,
    }
  },

  mounted() {
    this.isDisabled =
      JSON.parse(sessionStorage.getItem('fromQuery')) == 10 ? true : false
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    this.showControlCenter()
    this.getUserPic()
    this.getPostAudFunStatus()
    //<--------------7S管理------------->//
    // if (this.$route.query.fromQuery == '5') {
    //   this.isDisabled = true
    //   return
    // }
    // 基础设置除股份角色，普通角色职能看到修改登录密码
    var roleName = JSON.parse(
      sessionStorage.getItem('userInfo')
    ).roleName.split(',')
    if (roleName.indexOf('股份公司管理员') == -1) {
      // this.$store.state.menuList[0].son = [
      //   {
      //     path: '更改密码',
      //     title: '更改登录密码',
      //   },
      // ]
      this.leftList.splice(4, 1) //有股份公司管理员权限才显示统计分析
      this.menuList[1].son.splice(3, 1)
      this.$store.dispatch(
        //有股份公司管理员权限才显示岗位职能权限设置
        'menuList',
        this.menuList
      )
    } else {
      this.menuList[1].son.splice(3, 0, {
        path: '/post-fun-set',
        title: '岗位职能表权限设置',
      })
      this.$store.dispatch('menuList', this.menuList)
    }
    //控制中心
    if (this.$route.query.fromPagePath) {
      if (
        this.$route.query.fromPagePath == 'ControlPage' &&
        this.User.userNo != 'admin' &&
        this.User.userNo != 'S010455' &&
        this.User.userNo != 'S015077' &&
        this.User.userNo != 'S050857'
      ) {
        this.$message({
          type: 'warning',
          message: '抱歉，您无权限查看',
        })
        this.$router.go(-1).catch(() => {})
        return
      }
      //从page跳进homg的子页
      this.leftListIndex = this.$route.query.leftIndex
      this.pageTitle = this.$route.query.title
      var path = './' + this.$route.query.fromPagePath
      //文件检查需求：
      //①从page的四个股份公司跳过来文件检查，要保存它四个参数，并且在home跳到任何页面再跳回文件检查都要传参。
      //②在page不是从四个股份公司跳到home的，不需要保存它的参数，跳到文件检查时不传参或传10即可
      //注：从审核中心跳进来到文件检查，改为默认跳到混合审核
      sessionStorage.setItem(
        'fromQuery',
        this.pageTitle == '审核中心' ? this.$route.query.fromQuery : 10
      )
      this.isDisabled = this.pageTitle != '审核中心' ? true : false
      this.$router
        .push(
          this.$route.query.fromQuery
            ? path + '?fromQuery=' + this.$route.query.fromQuery
            : path
        )
        .catch(() => {})
      return
    }

    if (JSON.parse(sessionStorage.getItem('pathName')) != null) {
      var pathStorage = JSON.parse(sessionStorage.getItem('pathName'))
      this.$router.push(pathStorage.path).catch(() => {})
      this.leftListIndex = pathStorage.leftIndex
      this.pageTitle = pathStorage.name
      this.chindIndex = pathStorage.chindIndex
      this.activeIndex = pathStorage.menuIndex
      sessionStorage.setItem('pathName', null)
    }
  },
  watch: {
    $route(n, o) {
      this.oldValpath = o.path
      this.newValpath = n.path
      if (n.path == '/exam' && o.path == '/test') {
        this.pageTitle = '考试题库'
      }
    },
  },

  methods: {
    getPostAudFunStatus() {
      //岗位职能表入口显示
      get('/api/PostAuthFunc').then((resp) => {
        if (resp.code == 200) {
          if (!resp.data) {
            this.menuList[2].son.splice(this.menuList[2].length, 1)  //返回值为false，则去除岗位职能表
          }
          this.$store.dispatch('postAudFunStatus', resp.data)
          this.$store.dispatch('menuList', this.menuList)
        }
      })
    },
    beforeunloadFn() {
      var path = this.$router.currentRoute.fullPath
      sessionStorage.setItem(
        'pathName',
        JSON.stringify({
          path: path,
          name: this.pageTitle,
          leftIndex: this.leftListIndex,
          chindIndex: this.chindIndex,
          menuIndex: this.activeIndex,
        })
      )
    },
    showControlCenter() {
      get('/api/Supervision/GetIsAuthority').then((resp) => {
        this.controlPageIndex = resp.data //0：false,1：true,2：true&&控制中心的（混合、7s检查）隐藏oa接口添加确定接口
      })
    },
    handleSelect(path, item) {
      var roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName
      if (!new RegExp('股份公司管理员').test(roleName) && !roleName) {
        this.$message({
          type: 'warning',
          message: '抱歉，没有权限',
        })
        return
      }
      
      if (path == '同步OA') {
        this.dialogIndex = 1
        this.showDialog = true
        return
      }
      if (path == '更改密码') {
        this.dialogIndex = 2
        this.showDialog = true
        return
      }
      this.leftListIndex = null
      this.chindIndex = null
      this.showI = false
      this.activeIndex = path
      this.$router.push(path)
      this.$store.state.menuList[item[0]].son.map((item) => {
        if (item.path == path) {
          this.pageTitle = item.title
        }
      })
    },
    clickLeftList(i, item) {
      if (this.isDisabled && item.text == '审核中心') return
      var roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName
      if (
        item.path == '/archives-manage' &&
        !new RegExp('公司管理员').test(roleName) &&
        !new RegExp('股份公司安全管理员').test(roleName) &&
        !new RegExp('公司安全管理员').test(roleName)
      ) {
        this.$message({
          type: 'warning',
          message: '抱歉，您无权限查看',
        })
        return
      }
      this.pageShowTitle = item.text
      if (
        this.showI &&
        i == this.leftListIndex &&
        (item.text == '文件落地检查' ||
          item.text == '学习中心' ||
          item.text == '统计分析')
      ) {
        this.showI = false
      } else {
        this.showI = true
      }
      this.leftListIndex = i
      this.activeIndex = null
      if (
        item.text == '文件落地检查' ||
        item.text == '学习中心' ||
        item.text == '统计分析'
      )
        return
      this.pageTitle = item.text
      this.chindIndex = null
      this.$router.push(item.path)
    },
    clickChind(i, item) {
      this.chindIndex = i
      this.pageTitle = item.text
      this.$router.push(item.path)
    },
    handleClose() {
      this.dialogIndex = 0
      this.showDialog = false
    },
    loginUp() {
      this.$confirm('您确定要退出?').then(() => {
        sessionStorage.clear()
        this.$message({
          type: 'success',
          message: '退出成功',
        })
        this.$router.push('./login')
      })
    },
    handlePictureCardPreview() {},
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file)
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result)
          }
        })
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          this.$refs.upload.clearFiles()
          if (res.code == 200) {
            var data = {
              id: !this.userPic.id ? 0 : this.userPic.id,
              userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
              imagesAddr: this.BUrl + '/uploads/' + res.value,
            }
            post('/api/UserImage', data)
              .then((resp) => {
                if (resp.code == 200) {
                  this.getUserPic()
                } else {
                  this.$message.error(resp.message)
                }
              })
              .catch(() => {
                this.$message.error('上传失败')
              })
            this.$refs.upload.clearFiles()
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles()
          this.$message.error('上传失败')
        })
    },
    getUserPic() {
      get(
        '/api/UserImage?UserId=' +
          JSON.parse(sessionStorage.getItem('userInfo')).id
      ).then((resp) => {
        if (resp.code == 200) {
          if (resp.value == null) {
            this.userPic.imagesAddr = require('../../assets/images/img1/header.png')
            return
          }
          this.userPic = resp.value
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.left {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #99a9bf;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #d3dce6;
  }
}
.el-main {
  padding: 10px 0 0 10px;
}

.left {
  position: relative;
  background-color: #05275b;
  color: white;

  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;

    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 10px;
    }

    div {
      text-align: center;

      & p:nth-of-type(1) {
        font-size: 14px;
      }

      & p:nth-of-type(2) {
        background-color: #5a8bff;
        padding: 3px 10px;
        border-radius: 50px;
        margin-top: 5px;
      }
    }
  }
  .other {
    // height: 530px;
    height: calc(100% - 166px);
    overflow-y: auto;

    .item {
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 10px 0;
      padding-left: 30px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span {
        width: 100px;
        text-align: start;
        display: inline-block;
      }
    }

    .chind {
      position: relative;
      padding: 10px 0 15px 70px;

      .poin {
        position: absolute;
        color: #5a8bff;
        font-size: 16px;
        left: 50px;
        top: 7px;
      }

      .chindActive {
        color: #5a8bff;
        font-weight: bold;
      }
    }

    .leftActive {
      font-weight: bold;
      background: linear-gradient(to right, #72b8f9, #0d71ec);
    }
  }

  .news {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #2549807f;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
      padding: 15px 0;

      img {
        width: 23px;
        height: 23px;
        margin-right: 5px;
      }
    }
  }
}

.el-header {
  padding: 0 !important;
}

.header {
  position: relative;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .title {
    position: absolute;
    left: 40px;

    span {
      color: #636363;
      font-weight: bold;
      font-size: 18px;
      margin-left: 10px;
    }
  }

  .backimg {
    width: 15px;
    height: 15px;
  }

  .pack-up {
    text-align: center;
    font-size: 13px;
    margin-left: 15px;
    color: #5a8bff;
    border: 1px solid #dee1e7;
    padding: 10px 15px;
    background-color: #e6e9f2;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.main {
  background-color: #e7e9ec;
}

.about {
  font-size: 14px;
  margin: 0 15px;
}

// 菜单

/deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}

/deep/ .el-submenu.is-active .el-submenu__title {
  border-bottom-color: transparent !important;
}

/deep/ .el-menu-item {
  border-top: 1px solid rgb(233, 232, 232);
  padding-left: 20px !important;
}

/deep/ .el-menu--popup {
  padding: 0 !important;
}

.el-menu-item:hover {
  // border-right: 5px solid #5a8bff;
  color: #5a8bff !important;
  font-weight: bold;
}
.disableClass {
  color: #afaaaab3 !important;
}
</style>
